import { loadStripe } from '@stripe/stripe-js';

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe("pk_live_51Nieu0DW3LWg0ms4KxArZblmZZU9PCoJcU1q552Ki97jJUrc2IZNKmVD0mo1QuzIaL3uHHnHHglhvRm60lNeNTCp00D9l6f2lQ");
  }
  return stripePromise;
};

export default getStripe;