import { Button, Col, Container, Form, Row } from "react-bootstrap"
import Page from "../Components/Page"
import { useState } from "react"
import { Product } from "../App"
import { Rating } from 'react-simple-star-rating'
import { v4 as uuidv4 } from 'uuid';
import { FaStar } from "react-icons/fa"
interface Props {
    basket: string[]
    products: Product[]
    submitReview: (review: Review) => void
    allReviews: Review[]
}

export interface Review {
    fullName: string;
    sport: string;
    product: string;
    message: string;
    rating: number;
    approved: boolean;
    id: string;
}
const Reviews = ({ basket, products, submitReview, allReviews }: Props) => {
    const [fullName, setFullName] = useState<string>("")
    const [sport, setSport] = useState<string>("")
    const [product, setProduct] = useState<string>("")
    const [message, setMessage] = useState<string>("")
    const [rating, setRating] = useState<number>(0)
    const formatReview = () => {
        const review: Review = {
            id: uuidv4(),
            fullName,
            rating,
            message,
            product,
            sport,
            approved: false
        }
        submitReview(review)
    }
    const icons = [<FaStar color="#E8A43F" size={20} />,
    <FaStar color="#E8A43F" size={20} />,
    <FaStar color="#E8A43F" size={20} />,
    <FaStar color="#E8A43F" size={20} />, <FaStar color="#E8A43F" size={20} />]
    return (
        <Page basket={basket} page="Reviews">
            <div className="smallLanding">
                <h1 className="smallLanding__title">WHAT PEOPLE ARE SAYING</h1>
                <p className="smallLanding__desc">

                Discover what athletes are raving about on our Reviews Page! Our Grip Sports Socks have garnered praise for their game-changing performance and unbeatable comfort. Dive into a collection of testimonials from fitness enthusiasts, athletes, and everyday champions who have experienced the Flex difference.
                </p>
            </div>
            <Container className="pt-2">
                <div>
                    <Container>
                        <Col>
                            {allReviews.map((review) => {
                                if (review.approved === true) {
                                    return (
                                        <Row>
                                            <hr/>
                                            <div className="review-item">
                                                <div className="d-flex flex-row justify-content-between align-items-center">
                                                    <div className="d-flex flex-column">
                                                        <p className="review-item__name">{review.fullName.toLocaleUpperCase()}</p>
                                                        <p className="review-item__sport">{review.sport}</p>
                                                    </div>
                                                    <div className="d-flex flex-row">
                                                        {icons.map((ico, index) => {
                                                            if (index <= (review.rating - 1)) {
                                                                return ico
                                                            }
                                                            if (Number.isNaN(review.rating) && index < 1) {
                                                                return <FaStar color="#E8A43F" size={20} />
                                                            }
                                                        })}
                                                    </div>
                                                </div>
                                                <p>{review.message}</p>
                                            </div>
                                        </Row>
                                    )
                                }
                            })}
                        </Col>
                    </Container>
                    <hr />
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Full Name:</Form.Label>
                                    <Form.Control value={fullName} onChange={(e) => setFullName(e.currentTarget.value)} type="email" placeholder="Enter full name..." />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Sport:</Form.Label>
                                    <Form.Control value={sport} onChange={(e) => setSport(e.currentTarget.value)} type="text" placeholder="Enter sport/position..." />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Product:</Form.Label>
                                    <Form.Select value={product} onChange={(e) => setProduct(e.currentTarget.value)} aria-label="Default select example">
                                        <option>Select a product...</option>
                                        {products.map((item) => <option value={item.stripeId}>{item.title}</option>)}
                                        <option value="Other">Other</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3 d-flex flex-column">
                                    <Form.Label>Rating:</Form.Label>
                                    <Rating
                                        onClick={(num) => setRating(num)}
                                        size={30}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className="mb-3">
                            <Form.Label>Message:</Form.Label>
                            <Form.Control value={message} onChange={(e) => setMessage(e.currentTarget.value)} as="textarea" rows={3} placeholder="Enter a message..." />
                        </Form.Group>
                        <div className="d-flex justify-content-end">
                            <Button className="flexButton mb-3" onClick={() => formatReview()}>SUBMIT REVIEW</Button>
                        </div>
                    </Form>
                </div>
            </Container>
        </Page>
    )
}

export default Reviews