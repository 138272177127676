import { Col, Container, Row } from "react-bootstrap"
import Page from "../Components/Page"
import ReviewCara from "../Components/ReviewCara"
import ItemCard from "../Components/ItemCard"
import { FaSocks } from "react-icons/fa"
import Card from "../Components/Card"
interface Props {
    basket: string[]
}
const AboutUs = ({basket}: Props) => {
    return (
        <Page basket={basket} page="About Us">
            <div className="smallLanding">
                <h1 className="smallLanding__title">DESIGNED & DEVELOPED
                    BY ATHLETES, FOR ATHLETES</h1>
                <p className="smallLanding__desc">
                    
Welcome to Flex, where innovation meets performance in the world of grip sports socks. At Flex, we are passionate about redefining athletic wear with our cutting-edge Grip Sports Socks collection. Our commitment is to empower athletes with socks that blend advanced grip technology and superior comfort.
                </p>
            </div>
            <div className="aboutUs-tech pt-5 pb-5">
                <Container>
                    <Row>
                        <Col sm={7} className="d-flex justify-content-center flex-column">
                            <h2 className="color-white mb-4">TECHNOLOGY EMBEDDED IN OUR SPORTS PRODUCTS</h2>
                            <p className="color-white mb-3">
                            Founded with a vision to elevate your performance, Flex is more than just a sock company – it's a commitment to excellence. Our Grip Sports Socks are meticulously crafted to provide stability and confidence, allowing you to push your limits in any sporting endeavor.
                            </p>
                            <p className="color-white">
                            What sets us apart? Beyond the innovative design, Flex is dedicated to creating a community of like-minded individuals who share a passion for fitness and a commitment to reaching their goals. We understand that success is built step by step, and we're here to support you on that journey.
                            </p>
                        </Col>
                        <Col sm={5}>
                        <Card
                            title="COMPRESSIVE FABRIC"
                            desc="The fabric on the upper and lower parts of the sock helps to make snug fit for the user. Due to the tightness, our sock has the perfect fit to prevent the chances of blood pooling and poor circulation."
                            icon={<FaSocks color="white" size={30} />} />
                             <Card
                            title="THICKENED SOLE"
                            desc="The thickened sole on the bottom of the sock allows for improved comfort and softness on the soles of your foot. This reduces the strain and stress on your foot and is ideal for sports consisting of long-distance running."
                            icon={<FaSocks color="white" size={30} />} />
                             <Card
                             className="m-0"
                             title="RUBBER PADS"
                                    desc="The grippy rubber pads on the sole diminishes the movement of your feet within your footwear so blistering doesn’t occur. The rubber pads also develop stability in your footwear which helps to prevent injuries."
                                    icon={<FaSocks color="white" size={30} />} />
                        </Col>
                    </Row>
                </Container>
            </div>
            <ReviewCara />
        </Page>
    )
}

export default AboutUs