interface Props {
    title: string;
    desc: string;
    icon: React.ReactNode;
    className?: string;
}

const Card = ({title, desc, icon, className}: Props) => {
    return (
        <div className={`card ${className}`}>
            <div className="card-orange">
                {icon}
            </div>
            <p className="card__title">{title}</p>
            <p className="card__desc">{desc}</p>
        </div>
    )
}

export default Card