import React from "react"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import { FaShoppingCart, FaFacebookSquare, FaInstagramSquare } from "react-icons/fa"
import { IoMenu, IoCart } from "react-icons/io5";

import blpng from "../Images/blpng.png"
import whpng from "../Images/whpng.png"
import getStripe from "../Scripts/Stripe"
import { Offcanvas } from "react-bootstrap";

interface Props {
    children: React.ReactNode
    page: string
    basket: string[]
}

const Page = ({ children, page, basket }: Props) => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [])
    const tabletMedia = window.matchMedia("(max-width: 1250px)").matches
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className="page">
            {tabletMedia ?
            <>
              <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <img src={blpng} className="page-nav__logo" />
                <ul className="page-nav-links">
                    <Link to="/" className={`page-nav-links__link ${page === "Home" && `page-nav-links__link--active`}`}>Home</Link>
                    <Link to="/about-us" className={`page-nav-links__link ${page === "About Us" && `page-nav-links__link--active`}`}>About Us</Link>
                    <Link to="/all-products" className={`page-nav-links__link ${page === "All Products" && `page-nav-links__link--active`}`}>All Products</Link>
                    <Link to="/reviews" className={`page-nav-links__link ${page === "Reviews" && `page-nav-links__link--active`}`}>Reviews</Link>
                    <Link to="/contact-us" className={`page-nav-links__link ${page === "Contact Us" && `page-nav-links__link--active`}`}>Contact Us</Link>
                </ul>
        </Offcanvas.Body>
      </Offcanvas>
      <div className="page-mobile-nav">
      <Link to="/checkout"><IoCart size={50} color={basket.length > 0 ? "#E8A43F" : "#000000"}/></Link>
      <IoMenu size={50} onClick={() => handleShow()}/>
      </div>
            </>
        :
<div className="page-nav">
                <img src={blpng} className="page-nav__logo" />
                <ul className="page-nav-links">
                    <Link to="/" className={`page-nav-links__link ${page === "Home" && `page-nav-links__link--active`}`}>Home</Link>
                    <Link to="/about-us" className={`page-nav-links__link ${page === "About Us" && `page-nav-links__link--active`}`}>About Us</Link>
                    <Link to="/all-products" className={`page-nav-links__link ${page === "All Products" && `page-nav-links__link--active`}`}>All Products</Link>
                    <Link to="/reviews" className={`page-nav-links__link ${page === "Reviews" && `page-nav-links__link--active`}`}>Reviews</Link>
                    <Link to="/contact-us" className={`page-nav-links__link ${page === "Contact Us" && `page-nav-links__link--active`}`}>Contact Us</Link>
                </ul>
                <Link to={"/checkout"}><FaShoppingCart size={20} color={basket.length > 0 ? "#E8A43F" : "#000000"}/></Link>
            </div>
        }
            
            {children}
            <div className="page-footer">
                <img src={whpng} className="page-footer__logo" />
                <ul className="page-footer-links">
                    <Link to="/" className="page-footer-links__link">Home</Link>
                    <Link to="/about-us" className="page-footer-links__link">About Us</Link>
                    <Link to="/all-products" className="page-footer-links__link">All Products</Link>
                    <Link to="/reviews" className="page-footer-links__link">Reviews</Link>
                    <Link to="/contact-us" className="page-footer-links__link">Contact Us</Link>
                </ul>
                <div>
                <FaInstagramSquare size={20} color="#ffffff"/>
                <FaFacebookSquare size={20} color="#ffffff"  />
                </div>
            </div>
        </div>
    )
}

export default Page