import { Button, Container, Row, Col, Carousel } from "react-bootstrap"
import Page from "../Components/Page"
import socks from "../Images/sock-removebg-preview.png"
import sock from "../Images/sock.png"
import Card from "../Components/Card"
import { FaSocks } from "react-icons/fa"
import ItemCard from "../Components/ItemCard"
import ReviewCara from "../Components/ReviewCara"
import { Product } from "../App"
interface Props {
    addToBasket: (itemId: string) => void
    allProducts: Product[]
    basket: string[]
}

const Home = ({ addToBasket, basket, allProducts }: Props) => {
    const average = (arr: number[]) => {
        let total = 0
        arr.map((num) => {
            total += num
        })
        return total / arr.length
    }

    const splitChunks = (arr: Product[]) => {
        const tempOne: Product[] = []
        const tempTwo: Product[] = []
        const tempThree: Product[] = []
        let i = 1
        arr.map((item) => {
            if (i === 4) {
                i = 1
            }
            if (i === 1) {
                tempOne.push(item)
            }
            if (i === 2) {
                tempTwo.push(item)
            }
            if (i === 3) {
                tempThree.push(item)
            }
            i = i + 1
        })
        return [tempOne, tempTwo, tempThree]
    }
    return (
        <Page basket={basket} page="Home">
            <div className="home-landing">
                <div className="home-landing-content">
                    <img src={socks} className="home-landing-content__image" />
                    <div className="home-landing-content-info">
                        <h1 className="mb-3">BOOST YOUR ALL ROUND PERFORMANCE</h1>
                        <p className="mb-3">FLEX Official is a top of the range premium grip socks supplier with latest technology to help your
                            sports performance reach new levels. We at FLEX Official know that wearing the right gear helps to
                            give you that slight competitive edge and we want to allow users to reach their maximum potential.</p>
                        <Button className="flexButton">VIEW PRODUCTS</Button>
                    </div>
                </div>
            </div>
            <div className="home-abilities-bg">
                <Container className="home-abilities">
                    <h2>ENHANCE YOUR MATCH DAY ABILITIES</h2>
                    <p className="home-abilities__desc">Join us at Flex and experience the perfect fusion of style, performance, and comfort. We invite you to explore our Grip Sports Socks collection and step into a world where every move feels empowered and every step takes you closer to your athletic aspirations</p>
                    <div className="d-flex flex-wrap justify-content-evenly">
                        <Row>
                            <Col>
                                <Card
                                    title="COMPRESSIVE FABRIC"
                                    desc="The fabric on the upper and lower parts of the sock helps to make snug fit for the user. Due to the tightness, our sock has the perfect fit to prevent the chances of blood pooling and poor circulation."
                                    icon={<FaSocks color="white" size={30} />} />
                            </Col>
                            <Col>
                                <Card
                                    title="THICKENED SOLE"
                                    desc="The thickened sole on the bottom of the sock allows for improved comfort and softness on the soles of your foot. This reduces the strain and stress on your foot and is ideal for sports consisting of long-distance running."
                                    icon={<FaSocks color="white" size={30} />} />
                            </Col>
                            <Col>
                                <Card
                                    title="RUBBER PADS"
                                    desc="The grippy rubber pads on the sole diminishes the movement of your feet within your footwear so blistering doesn’t occur. The rubber pads also develop stability in your footwear which helps to prevent injuries."
                                    icon={<FaSocks color="white" size={30} />} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <div className="home-products">
                <Container>
                    <div className="home-products-container">
                        <h2>VIEW ALL OF OUR PRODUCTS</h2>
                        <p className="home-products-container__desc">
                            Step up your game with our Grip Sports Socks – the perfect blend of performance and comfort. Engineered with advanced grip technology, these socks provide unmatched stability, keeping you surefooted during every workout. Designed for athletes who demand excellence, our socks feature moisture-wicking fabrics and strategic ventilation for all-day comfort.</p>

                    </div>
                    <Row>

                        <Col className="col-p" sm={4}>
                            {splitChunks(allProducts)[0].map((item) => {
                                return (
                                    <ItemCard itemId={item.stripeId} addToBasket={(id) => addToBasket(id)} stars={average(item.rating)} reviews={item.rating.length.toString()} image={item.file || ""} price={item.price} item={item.title} />

                                )
                            })}
                        </Col>
                        <Col className="col-p" sm={4}>
                            {splitChunks(allProducts)[1].map((item) => {
                                return (
                                    <ItemCard itemId={item.stripeId} addToBasket={(id) => addToBasket(id)} stars={average(item.rating)} reviews={item.rating.length.toString()} image={item.file || ""} price={item.price} item={item.title} />

                                )
                            })}
                        </Col>
                        <Col className="col-p" sm={4}>
                            {splitChunks(allProducts)[2].map((item) => {
                                return (
                                    <ItemCard itemId={item.stripeId} addToBasket={(id) => addToBasket(id)} stars={average(item.rating)} reviews={item.rating.length.toString()} image={item.file || ""} price={item.price} item={item.title} />

                                )
                            })}
                        </Col>
                    </Row>
                </Container>
            </div>
            <ReviewCara />
        </Page>
    )
}

export default Home