import React, { useEffect, useState } from 'react';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Home from './Pages/Home';
import AboutUs from './Pages/AboutUs';
import AllProducts from './Pages/AllProducts';
import Reviews, { Review } from './Pages/Reviews';
import ContactUs from './Pages/ContactUs';
import Checkout from './Pages/Checkout';
import { Routes, Route } from 'react-router-dom';
import { FieldValue, arrayUnion, collection, doc, getDocs, getFirestore, setDoc, updateDoc } from "firebase/firestore";
import getStripe from './Scripts/Stripe';
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import Login from './Pages/Login';
import Cms from './Pages/Cms';
import { ToastContainer, toast } from 'react-toastify';


export interface Product {
  title: string;
  price: string;
  stripeId: string;
  rating: number[];
  image?: string;
  file?: string;
}

function App() {
  const [basket, setBasket] = useState<string[]>([])
  const [allProducts, setAllProducts] = useState<Product[]>([])
  const [allReviews, setAllReviews] = useState<Review[]>([])
  const addToBasket = (id: string) => {
    setBasket([...basket, id])
  }

  const firebaseConfig = {
    apiKey: "AIzaSyDi4zKvspBgKnFqClEk3cJdP_Y-5cyKJm4",
    authDomain: "flex-16668.firebaseapp.com",
    projectId: "flex-16668",
    storageBucket: "flex-16668.appspot.com",
    messagingSenderId: "705594110842",
    appId: "1:705594110842:web:3a2d722dc3a28b8397dcc3",
    measurementId: "G-XPTQSCFELL"
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const db = getFirestore(app);
  const storage = getStorage(app);

  const getProducts = async () => {
    const temp: Product[] = []
    const querySnapshot = await getDocs(collection(db, "Products"));
    querySnapshot.forEach((doc) => {
      //@ts-ignore
      temp.push(doc.data())
    });
    setAllProducts(temp)
  }

  const getReviews = async () => {
    const temp: Review[] = []
    const querySnapshot = await getDocs(collection(db, "Reviews"));
    querySnapshot.forEach((doc) => {
      //@ts-ignore
      temp.push(doc.data())
    });
    setAllReviews(temp)
  }

  const saveProduct = async (product: Product, file: File) => {
    uploadBytes(ref(storage, `/Files/${file.name}`), file).then(async () => {
      const imageRef = ref(storage, `Files/${file.name}`)
      await getDownloadURL(imageRef).then(async (url) => {
        await setDoc(doc(db, "Products", product.stripeId), { ...product, file: url });
        toast.success('New product created', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
    }).then(() => getProducts())
  }

  const saveReview = async (review: Review) => {
    await setDoc(doc(db, "Reviews", review.id), review).then(async () => {
      const prodRef = doc(db, "Products", review.product);
      await updateDoc(prodRef, {
        rating: arrayUnion(review.rating)
      })
    });
  }

  const approveReview = async (approved: boolean, review: Review) => {
    const revRef = doc(db, "Reviews", review.id);
      await updateDoc(revRef, {
        approved: approved
      }).then(() => getReviews())
  }

  const handleCheckout = async (items: {
    price: string,
    quantity: number,
  }[], email: string) => {
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      lineItems: items,
      mode: 'payment',
      successUrl: `https://flexofficial.co.uk/`,
      cancelUrl: `https://flexofficial.co.uk/`,
      customerEmail: email,
    });
    console.warn(error.message);
  }

  useEffect(() => {
    getProducts()
    getReviews()
  }, [])

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Routes>
        <Route path='/' element={<Home allProducts={allProducts} basket={basket} addToBasket={(id) => addToBasket(id)} />} />
        <Route path='/about-us' element={<AboutUs basket={basket} />} />
        <Route path='/all-products' element={<AllProducts allProducts={allProducts} basket={basket} addToBasket={(id) => addToBasket(id)} />} />
        <Route path='/reviews' element={<Reviews allReviews={allReviews} submitReview={(review) => saveReview(review)} products={allProducts} basket={basket} />} />
        <Route path='/contact-us' element={<ContactUs basket={basket} />} />
        <Route path='/checkout' element={<Checkout allProducts={allProducts} payment={(items, email) => handleCheckout(items, email)} basket={basket} />} />
        <Route path='/login' element={<Login />} />
        <Route path='/admin-portal' element={<Cms approveReview={(approved, review) => approveReview(approved, review)} createProduct={(product, file) => saveProduct(product, file)} allReviews={allReviews} allProducts={allProducts} />} />
      </Routes>
    </>
  );
}

export default App;
