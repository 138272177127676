import { Button, Col, Form, Row } from "react-bootstrap"
import { Product } from "../App"
import whpng from "../Images/whpng.png"
import { BsShop, BsStarFill, BsFillTrashFill, BsFillPencilFill } from "react-icons/bs"
import sock from "../Images/sock.png"
import { useState } from "react"
import { toast } from "react-toastify"
import { Review } from "./Reviews"

interface Props {
    allProducts: Product[]
    allReviews: Review[]
    createProduct: (product: Product, file: File) => void
    approveReview: (approved: boolean, review: Review) => void
}

const Cms = ({ allProducts, createProduct, allReviews, approveReview }: Props) => {
    const [page, setPage] = useState<string>("Products")

    const [create, setCreate] = useState<boolean>(false)
    const [title, setTitle] = useState<string>("")
    const [price, setPrice] = useState<string>("")
    const [id, setId] = useState<string>("")
    const [file, setFile] = useState<File>()
    return (
        <div className="cms">
            <div className="cms-nav">
                <img src={whpng} className="cms-nav__img" />
                <BsShop onClick={() => setPage("Products")} color={page === "Products" ? "#E8A43F" : "White"} className="mb-4 cursor" size={30} />
                <BsStarFill onClick={() => setPage("Reviews")} color={page === "Reviews" ? "#E8A43F" : "White"} size={30} className="cursor" />
            </div>
            <div className="cms-content">
                <div className="cms-content-container">
                    {page === "Products" &&
                        <div className="d-flex flex-column w-100">
                            <Row>
                                <Col className="pb-4">
                                    <div className="d-flex justify-content-between mb-4">
                                        <h2 className="cms-content-container__title">ALL PRODUCTS</h2>
                                        <Button className="flexButton" onClick={() => setCreate(true)}>CREATE ITEM</Button>
                                    </div>
                                    {allProducts.map((product, index) => {
                                        return (
                                            <div className={`cms-product-item ${index === 0 && "cms-product-item--first"} ${index === allProducts.length && "cms-product-item--last"}`}>
                                                <div className="d-flex flex-row">
                                                    <div className="cms-product-item-grey">
                                                        <img src={product.file || ""} className="cms-product-item__image" />
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <h3>{product.title}</h3>
                                                        <p>Price: £{product.price}</p>
                                                        <p>Stripe ID: {product.stripeId}</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <BsFillTrashFill />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </Col>

                                <Col>
                                    {create &&
                                        <div>
                                            <div className="d-flex justify-content-between mb-4">
                                                <h2 className="cms-content-container__title">CREATE NEW ITEM</h2>
                                            </div>
                                            <p>Before creating a product here please create a product in Stripe as the price ID is needed.</p>
                                            <p className="mb-1">To create a product in stripe please follow this link.</p>
                                            <a target="_blank" className="mb-4" href="https://dashboard.stripe.com/products/create">https://dashboard.stripe.com/products/create</a>
                                            <Form.Group className="mb-3 mt-4">
                                                <Form.Label>Product Title:</Form.Label>
                                                <Form.Control value={title} onChange={(e) => setTitle(e.currentTarget.value)} type="text" placeholder="Enter product title..." />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Price:</Form.Label>
                                                <Form.Control value={price} onChange={(e) => setPrice(e.currentTarget.value)} type="text" placeholder="Enter price (no £ symbol)..." />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Stripe ID:</Form.Label>
                                                <Form.Control value={id} onChange={(e) => setId(e.currentTarget.value)} type="text" placeholder="Enter Stripe ID..." />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Upload Image:</Form.Label>
                                                <Form.Control accept="image/*" onChange={(e) => {
                                                    //@ts-ignore
                                                    setFile((e.target as HTMLInputElement).files[0])
                                                }} type="file" />                            </Form.Group>
                                            <Button className="flexButton" onClick={() => {
                                                if (file) {
                                                    createProduct({ price: price, rating: [], stripeId: id, title: title, image: file.name, file: `Files/${file.name}` }, file)
                                                    setId("")
                                                    setPrice("")
                                                    setFile(undefined)
                                                    setTitle("")
                                                    setCreate(false)
                                                } else {
                                                    toast.error("Please fill all fields")
                                                }
                                            }}>SAVE ITEM</Button>

                                        </div>
                                    }
                                </Col>
                            </Row>
                        </div>
                    }
                    {page === "Reviews" &&
                        <div className="d-flex flex-column w-100">
                            <Row>
                                <Col className="pb-4">
                                    <div className="d-flex justify-content-between mb-4">
                                        <h2 className="cms-content-container__title">UNAPPROVED REVIEWS</h2>
                                    </div>
                                    {allReviews.map((review, index) => {
                                        if(review.approved === false) {
                                            return (
                                                <div className={`cms-review-item ${index === 0 && "cms-product-item--first"}`}>
                                                    <Row>
                                                        <Col>
                                                            <p className="cms-review-item__label">Name:</p>
                                                            <p className="cms-review-item__text">{review.fullName}</p>
                                                            <p className="cms-review-item__label">Product ID:</p>
                                                            <p className="cms-review-item__text">{review.product}</p>
                                                        </Col>
                                                        <Col>
                                                            <p className="cms-review-item__label">Sport:</p>
                                                            <p className="cms-review-item__text">{review.sport}</p>
                                                            <p className="cms-review-item__label">Rating:</p>
                                                            <p className="cms-review-item__text">{review.rating}</p>
                                                        </Col>
                                                        <Col>
                                                            <p className="cms-review-item__label">Approval status:</p>
                                                            <p className="cms-review-item__text">{review.approved.toString().toLocaleUpperCase()}</p>
                                                        </Col>
                                                    </Row>
    
                                                    <p className="cms-review-item__label">Message:</p>
                                                    <p className="cms-review-item__text">{review.message}</p>
                                                    <div className="d-flex flex-row">
                                                        <Button onClick={() => approveReview(true, review)} className="flexButton mr-2">APPROVE REVIEW</Button>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        
                                    })}
                                </Col>

                                <Col>
                                    <div>
                                        <div className="d-flex justify-content-between mb-4">
                                            <h2 className="cms-content-container__title">APPROVED REVIEWS</h2>
                                        </div>
                                        {allReviews.map((review, index) => {
                                        if(review.approved === true) {
                                            return (
                                                <div className={`cms-review-item ${index === 0 && "cms-product-item--first"}`}>
                                                    <Row>
                                                        <Col>
                                                            <p className="cms-review-item__label">Name:</p>
                                                            <p className="cms-review-item__text">{review.fullName}</p>
                                                            <p className="cms-review-item__label">Product ID:</p>
                                                            <p className="cms-review-item__text">{review.product}</p>
                                                        </Col>
                                                        <Col>
                                                            <p className="cms-review-item__label">Sport:</p>
                                                            <p className="cms-review-item__text">{review.sport}</p>
                                                            <p className="cms-review-item__label">Rating:</p>
                                                            <p className="cms-review-item__text">{review.rating}</p>
                                                        </Col>
                                                        <Col>
                                                            <p className="cms-review-item__label">Approval status:</p>
                                                            <p className="cms-review-item__text">{review.approved.toString().toLocaleUpperCase()}</p>
                                                        </Col>
                                                    </Row>
    
                                                    <p className="cms-review-item__label">Message:</p>
                                                    <p className="cms-review-item__text">{review.message}</p>
                                                    <div className="d-flex flex-row">
                                                        <Button onClick={() => approveReview(false, review)} className="flexButton mr-2">REMOVE REVIEW</Button>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        
                                    })}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Cms