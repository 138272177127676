import { Button } from "react-bootstrap";
import { icons } from "react-icons";
import { FaStar } from "react-icons/fa"

interface Props {
    image: string;
    item: string;
    price: string;
    stars: number;
    reviews: string;
    addToBasket: (id: string) => void;
    itemId: string;
}

const ItemCard = ({ image, item, price, stars, reviews, addToBasket, itemId }: Props) => {
    const icons = [<FaStar color="#E8A43F" size={20} />,
     <FaStar color="#E8A43F" size={20} />,
      <FaStar color="#E8A43F" size={20} />,
       <FaStar color="#E8A43F" size={20} />, <FaStar color="#E8A43F" size={20} />]
console.log(stars)
    return (
        <div className="itemCard">
            <div className="itemCard-imgCont">
            <img src={image} className="itemCard__image" />

            </div>
            <div className="itemCard-info">
                <p className="itemCard-info__item">{item}</p>
                <div className="itemCard-info-extra">
                    <p className="itemCard-info-extra__price">£{price}</p>
                    <div className="itemCard-info-extra-stars">
                        {icons.map((ico, index) => {
                            if(index <= (stars - 1)) {
                                return ico
                            }
                            if(Number.isNaN(stars) && index < 1) {
                                return <FaStar color="#E8A43F" size={20} />
                            }
                        })}
                        <p>{`(${reviews})`}</p>
                    </div>
                </div>
                <Button className="flexButton" onClick={() => addToBasket(itemId)}>ADD TO BASKET</Button>

            </div>
        </div>
    )
}

export default ItemCard