import { Carousel } from "react-bootstrap"

const ReviewCara = () => {
    return (
        <Carousel className="home-cara">
            <Carousel.Item className="home-cara-item home-cara-item-one">
                <h2 className="home-cara-item__quote">“Top quality socks. Not once had a problem with blisters or sore feet since using!” </h2>
                <div className="home-cara-item-person">
                    <img className="home-cara-item-person__image" />
                    <div className="home-cara-item-person-info">
                        <p className="home-cara-item-person-info__name">QUEVIN CASTRO</p>
                        <p className="home-cara-item-person-info__sport">Football Player</p>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item className="home-cara-item home-cara-item-two">
                <h2 className="home-cara-item__quote">“Best socks on the market! Got recommended by a friend and now my whole rugby team use them. Super comfortable.” </h2>
                <div className="home-cara-item-person">
                    <div className="home-cara-item-person-info">
                        <p className="home-cara-item-person-info__name">JESSE SENE-LEFAO</p>
                        <p className="home-cara-item-person-info__sport">Rugby Player</p>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item className="home-cara-item home-cara-item-three">
                <h2 className="home-cara-item__quote">“These socks travel around the world with me! I’ve tried many different brands, but FLEX is the best by a country mile” </h2>
                <div className="home-cara-item-person">
                    <div className="home-cara-item-person-info">
                        <p className="home-cara-item-person-info__name">JOE DIXON</p>
                        <p className="home-cara-item-person-info__sport">England Football Player</p>
                    </div>
                </div>
            </Carousel.Item>
        </Carousel>
    )
}

export default ReviewCara