import { Button, Col, Container, Form, Row } from "react-bootstrap"
import Page from "../Components/Page"

interface Props {
    basket: string[]
}
const ContactUs = ({ basket }: Props) => {
    return (
        <Page basket={basket} page="Contact Us">
            <div className="smallLanding">
                <h1 className="smallLanding__title">GET IN TOUCH WITH US</h1>
                <p className="smallLanding__desc">         
Connect with us at Flex – your gateway to exceptional grip sports socks and unparalleled customer service. The Contact Us page is your direct line to the Flex team, where we're ready to assist with any inquiries, feedback, or support you may need.
                </p>

            </div>
            <Container className="contact">
                <Row>
                    <Col>
                        <p className="contact-sub">CONTACT DETAILS</p>
                        <p className="mb-4">
                        Whether you have questions about our Grip Sports Socks, need assistance with an order, or just want to share your thoughts, we're here for you. Reach out through the provided form, and our dedicated team will respond promptly to ensure your Flex experience is seamless.
                        </p>
                        <div className="d-flex flex-column">
                        <a href="mailto:info@flexofficial.co.uk">info@flexofficial.co.uk</a>
                        {/* <a href="tel:0123456789">0123456789</a> */}
                        </div>
                    </Col>
                    <Col>
                        <div className="contactBox">
                            <p className="contact-sub">USE THIS FORM TO LEAVE US A MESSAGE</p>
                            <Form.Group className="mb-3">
                                <Form.Label>Email Address:</Form.Label>
                                <Form.Control type="email" placeholder="Enter email address..." />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Full Name:</Form.Label>
                                <Form.Control type="text" placeholder="Enter full name..." />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Message:</Form.Label>
                                <Form.Control type="text" placeholder="Enter message..." />
                            </Form.Group>
                            <Form.Group className="mb-3">
                            <Form.Check // prettier-ignore
                                type={"checkbox"}
                                label={`You agree to our terms & conditions regarding contact
                                information being stored `}
                            />
                            </Form.Group>
                            <div className="d-flex justify-content-center align-items-center">
                                <Button className="flexButton" onClick={() => { }}>SUBMIT MESSAGE</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Page>
    )
}

export default ContactUs