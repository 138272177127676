import { Col, Container, Row } from "react-bootstrap"
import Page from "../Components/Page"
import ItemCard from "../Components/ItemCard"
import sock from "../Images/sock.png"
import { Product } from "../App"

interface Props {
    addToBasket: (itemId: string) => void
    allProducts: Product[]
    basket: string[]
}

const AllProducts = ({ addToBasket, basket, allProducts }: Props) => {
    const average = (arr: number[]) => {
        let total = 0
        arr.map((num) => {
            total += num
        })
        return total / arr.length
    }

    const splitChunks = (arr: Product[]) => {
        const tempOne: Product[] = []
        const tempTwo: Product[] = []
        const tempThree: Product[] = []
        let i = 1
        arr.map((item) => {
            if (i === 4) {
                i = 1
            }
            if (i === 1) {
                tempOne.push(item)
            }
            if (i === 2) {
                tempTwo.push(item)
            }
            if (i === 3) {
                tempThree.push(item)
            }
            i = i + 1
        })
        return [tempOne, tempTwo, tempThree]
    }
    return (
        <Page basket={basket} page="All Products">
            <div className="smallLanding">
                <h1 className="smallLanding__title">VIEW ALL PRODUCTS</h1>
                <p className="smallLanding__desc">
                Whether you're hitting the gym, court, or trails, elevate your athletic experience with our Grip Sports Socks – where confidence meets traction. Explore our collection and step into a world of unmatched support and style.
                </p>
            </div>
            <Container className="pt-5">
                <Row>
                    <Col className="col-p" sm={4}>
                        {splitChunks(allProducts)[0].map((item) => {
                            return (
                                <ItemCard itemId={item.stripeId} addToBasket={(id) => addToBasket(id)} stars={average(item.rating)} reviews={item.rating.length.toString()} image={item.file || ""} price={item.price} item={item.title} />

                            )
                        })}
                    </Col>
                    <Col className="col-p" sm={4}>
                        {splitChunks(allProducts)[1].map((item) => {
                            return (
                                <ItemCard itemId={item.stripeId} addToBasket={(id) => addToBasket(id)} stars={average(item.rating)} reviews={item.rating.length.toString()} image={item.file || ""} price={item.price} item={item.title} />

                            )
                        })}
                    </Col>
                    <Col className="col-p" sm={4}>
                        {splitChunks(allProducts)[2].map((item) => {
                            return (
                                <ItemCard itemId={item.stripeId} addToBasket={(id) => addToBasket(id)} stars={average(item.rating)} reviews={item.rating.length.toString()} image={item.file || ""} price={item.price} item={item.title} />

                            )
                        })}
                    </Col>
                </Row>
            </Container>
        </Page>
    )
}

export default AllProducts