import { Button, Form } from "react-bootstrap"
import blpng from "../Images/blpng.png"
const Login = () => {
    return (
        <div className="login">
            <div className="login-container">
                <img src={blpng} className="login-container__img"/>
                <Form.Group className="mb-3 w-100">
                    <Form.Label>Username:</Form.Label>
                    <Form.Control className="w-100" type="text" placeholder="Enter username..." />
                </Form.Group>
                <Form.Group className="mb-3 w-100">
                    <Form.Label>Password:</Form.Label>
                    <Form.Control className="w-100" type="password" placeholder="Enter password..." />
                </Form.Group>
                <div className="d-flex justify-content-center align-items-center">
                    <Button className="flexButton" onClick={() => {}}>SIGN IN</Button>
                </div>
            </div>
        </div>
    )
}

export default Login