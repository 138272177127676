import { Button, Col, Container, Form, Row } from "react-bootstrap"
import Page from "../Components/Page"
import { useState, useEffect } from "react"
import { Product } from "../App"

interface Props {
    basket: string[]
    allProducts: Product[]
    payment: (items: {
        price: string,
        quantity: number,
    }[], email: string) => void
}

const Checkout = ({ basket, payment, allProducts }: Props) => {
    const [items, setItems] = useState<{
        product: Product,
        quantity: number,
    }[]>([])
    const [email, setEmail] = useState<string>("")
    const createPayment = () => {
        const final = items.map((item) => {
            return (
                {
                    price: item.product.stripeId,
                    quantity: item.quantity
                }
            )
        })
        payment(final, email)
    }

    const sortItems = () => {
        const temp: {
            product: Product,
            quantity: number,
        }[] = []

        basket.map((id) => {
            let exists = false
            temp.map((item) => {
                if (item.product.stripeId === id) {
                    exists = true
                }
            })
            if (!exists) {
                allProducts.map((product) => {
                    if (product.stripeId === id) {
                        temp.push({ product: product, quantity: 1 })
                    }
                })
            }
            if (exists) {
                temp.map((item) => {
                    if (item.product.stripeId === id) {
                        item.quantity = item.quantity + 1
                    }
                })
            }
        })
        setItems(temp)
    }

    const getPrice = () => {
        let total = 0
        items.map((product) => {
            total = total + (product.quantity * parseInt(product.product.price))
        })
        return total
    }

    useEffect(() => {
        sortItems()
    }, [])
    return (
        <Page page="Checkout" basket={basket}>
            <div className="smallLanding">
                <h1 className="smallLanding__title">CHECKOUT</h1>
                <p className="smallLanding__desc">
                Welcome to the final step in securing your performance upgrade with Flex Grip Sports Socks! Our checkout page is designed for simplicity, speed, and security, ensuring a hassle-free experience as you finalize your order.
                </p>
            </div>
            <Container className="contact">
                <Row>
                    <Col col={7}>
                        <div className="checkout">
                            {items.map((item) => {
                                return (
                                    <div className="checkout-item">
                                        <div className="checkout-item-image">
                                            <img src={item.product.file} />
                                        </div>
                                        <div className="d-flex flex-column w-100">
                                            <p className="checkout-item__title">{item.product.title}</p>
                                            <p>Quantity: {item.quantity}</p>
                                            <p>Price: £{item.product.price}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="w-100 d-flex flex-row justify-content-between checkout-total">
                            <p className="font-bold">TOTAL</p>
                            <p className="font-bold">£{getPrice().toFixed(2)}</p>
                        </div>
                    </Col>
                    <Col sm={5}>
                        <div className="contactBox">
                            <p className="contact-sub">ENTER YOUR EMAIL TO CONTINUE WITH YOUR PAYMENT</p>
                            <Form.Group className="mb-3">
                                <Form.Label>Email Address:</Form.Label>
                                <Form.Control value={email} onChange={(a) => setEmail(a.currentTarget.value)} type="email" placeholder="Enter email address..." />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check // prettier-ignore
                                    type={"checkbox"}
                                    label={`You agree to your email being used for purchasing information only`}
                                />
                            </Form.Group>
                            <div className="d-flex justify-content-center align-items-center">
                                <Button className="flexButton" onClick={() => createPayment()}>CONTINUE PAYMENT</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Page>
    )
}

export default Checkout